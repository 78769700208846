import { Data } from "../../../Storage/Context-api";
// import { RWebShare } from "react-web-share";
import React, { useContext, useRef } from "react";
import resulimage from "../../../Assets/Images/Others/radiates.png";
import "./Result.css";
import { motion } from "framer-motion";
// import Lottie from "lottie-react";
import { useNavigate } from "react-router-dom";
import facebook from '../../../Assets/Images/PNG/facebook.png'
import twitter from '../../../Assets/Images/PNG/twittericon.png'
import whatsapp from '../../../Assets/Images/PNG/whatsapp.png'
import {
  FacebookShareButton,
  TwitterShareButton,
  WhatsappShareButton,
} from "react-share";

const ResultPage = ({ onClick, Result, name }) => {
  const choiceCtx = useContext(Data);

  // const shareYourResult = async () => {
  //   const shareOptions = {
  //     message: `I just used the "What Am I Feeling?" App \n and I'm in the mood for ${choiceCtx.Result}!`,
  //     url: choiceCtx.ResultImage,
  //   };
  //   try {
  //     const response = await RWebShare.open(shareOptions);
  //     console.log(
  //       "🚀 ~ file: ResultPage.js ~ line 21 ~ shareYouResult ~ response",
  //       response
  //     );
  //   } catch (error) {
  //     console.log(
  //       "🚀 ~ file: ResultPage.js ~ line 22 ~ shareYouResult ~ error",
  //       error
  //     );
  //   }
  // };

  // useEffect(() => {
  //   localStorage.getItem("finalresult");
  // }, []);





  const celebrationref = useRef();


  const navigate = useNavigate();

  return (
    <div>
      <div className="maincontainer">
        <motion.div
          initial={{ scale: 0.1 }}
          animate={{ scale: 1 }}
          transition={{ duration: 2 }}
          className="resultimgmaindiv"
        >
          <motion.div
            initial={{ scale: 0.4 }}
            animate={{ scale: 1 }}
            transition={{ duration: 2 }}
            className="resultimg"
          >
            <img src={resulimage} alt="" />
          </motion.div>
          <div className="rpic">
            <img className="khainimg" src={Result} alt="" />
            {/* <Lottie
            className="anima"
             ref={celebrationref}
              autoplay={true}
              loop={true}
              animationData={require("../../../Components/Loading/celebration.json")}
            /> */}
          </div>
        </motion.div>
        <div className="resultname" ref={celebrationref}>
          <h1>{name}</h1>
        </div>
        <div className="snextbtn">
          {/* <button onClick={shareYourResult}>Share your Result!</button> */}
          <div>
          <FacebookShareButton  quote={`I just used the What are you Feeling \n and Im in the mood for ${choiceCtx.Result}`}  title="What are you Feeling?" url="https://wayfmeals.com">
            <img src={facebook} alt="" />
          </FacebookShareButton>
          <TwitterShareButton quote={`I just used the What are you Feeling \n and Im in the mood for ${choiceCtx.Result}`} title="What are you Feeling?"  url="https://wayfmeals.com">
          <img src={twitter} alt="" />
          </TwitterShareButton>
          <WhatsappShareButton quote={`I just used the What are you Feeling \n and Im in the mood for ${choiceCtx.Result}`}  title="What are you Feeling?" url="https://wayfmeals.com">
              <img src={whatsapp} alt="" />
          </WhatsappShareButton>
          </div>
          <button onClick={() => navigate("/Final")}>Next</button>
        </div>
      </div>
    </div>
  );
};

export default ResultPage;
