import React from "react";
import "./TermandCondition.css";
import backarrow from "../../../../Assets/Images/Others/backarrow.png";
import { useNavigate } from "react-router-dom";

const TermandCondition = () => {
  const navigate = useNavigate();

  return (
    <div>
      <div className="termsetting">
        <div className="termback">
          <div className="term">
            <img src={backarrow} alt="" onClick={() => navigate("/Settings")} />
            <h2>Terms And Conditions</h2>
          </div>
        </div>
        <div className="pramaindiv">
          <div className="termpra1">
            <p>
              Please read these terms and conditions carefully before using Our
              Service.
            </p>
          </div>
          <h1>Interpretation and Definitions</h1>
          <hr className="line" />
          <div className="interpre">
            <h3>Interpretation</h3>
            <p>
              The words of which the initial letter is capitalized have meanings{" "}
              <br />
              defined under the following conditions. The following definitions{" "}
              <br />
              shall have the same meaning regardless of whether they appear in{" "}
              <br />
              singular or in plural
            </p>
          </div>
          <div className="defini">
            <h3>Definitions</h3>
            <p>For the purposes of these Terms and Conditions:</p>
            <div className="applica">
              <h3>Application</h3>
              <p>
                means the software program provided by the Company downloaded by{" "}
                <br />
                You on any electronic device, named What Are You Feeling?
              </p>
            </div>
            <div className="applica">
              <h3>Application Store</h3>
              <p>
                means the digital distribution service operated and developed by{" "}
                <br />
                Apple Inc. (Apple App Store) or Google Inc. (Google Play Store){" "}
                <br />
                in which the Application has been downloaded.
              </p>
            </div>
            <div className="applica">
              <h3>Affiliate</h3>
              <p>
                means an entity that controls, is controlled by or is under{" "}
                <br />
                common control with a party, where "control" means ownership of{" "}
                <br />
                50% or more of the shares, equity interest or other securities{" "}
                <br />
                entitled to vote for election of directors or other managing
                authority.
              </p>
            </div>
            <div className="applica">
              <h3>Country</h3>
              <p>refers to: United Kingdom</p>
            </div>
            <div className="applica">
              <h3>Company</h3>
              <p>
                (referred to as either "the Company", "We", "Us" or "Our" in{" "}
                <br />
                this Agreement) refers to JIO Technology Limited, England.
              </p>
            </div>
            <div className="applica">
              <h3>Device</h3>
              <p>
                means any device that can access the Service such as a computer,{" "}
                <br />a cellphone or a digital tablet
              </p>
            </div>
            <div className="applica">
              <h3>Service</h3>
              <p>refers to the Application.</p>
            </div>
            <div className="applica">
              <h3>Terms and Conditions</h3>
              <p>
                (also referred as "Terms") mean these Terms and Conditions that{" "}
                <br />
                form the entire agreement between You and the Company regarding{" "}
                <br />
                the use of the Service.
              </p>
            </div>
            <div className="applica">
              <h3>Third-party Social Media Service </h3>
              <p>
                means any services or content (including data, information,{" "}
                <br />
                products or services) provided by a third-party that may be{" "}
                <br />
                displayed, included or made available by the Service.
              </p>
            </div>
            <div className="applica">
              <h3>You</h3>
              <p>
                means the individual accessing or using the Service, or the{" "}
                <br />
                company, or other legal entity on behalf of which such <br />
                individual is accessing or using the Service, as applicable.
              </p>
            </div>
          </div>
          <h1>Acknowledgment</h1>
          <hr className="line" />
          <div className="pragraphs">
            <p>
              These are the Terms and Conditions governing the use of the What{" "}
              <br />
              Are You Feeling? App and the agreement that operates between You{" "}
              <br />
              and JIO Technology Limited. These Terms and Conditions set out the{" "}
              <br />
              rights and obligations of all users regarding the use of the
              Service.
            </p>
            <p>
              Your access to and use of the What Are You Feeling? App is <br />
              conditioned on Your acceptance of and compliance with these Terms{" "}
              <br />
              and Conditions. These Terms and Conditions apply to all visitors,{" "}
              <br />
              users and others who access or use the Service.
            </p>
            <p>
              By accessing or using the What Are You Feeling? App You agree to{" "}
              <br />
              be bound by these Terms and Conditions. If You disagree with any{" "}
              <br />
              part of these Terms and Conditions then You may not access the{" "}
              <br />
              What Are You Feeling? App.
            </p>
            <p>
              You represent that you are over the age of 13. JIO Technology{" "}
              <br />
              Limited does not permit those under 13 to use the Service.
            </p>
            <p>
              Your access to and use of the Service is also conditioned on Your{" "}
              <br />
              acceptance of and compliance with the Privacy Policy of the <br />
              Company. Our Privacy Policy describes Our policies and procedures{" "}
              <br />
              on the collection, use and disclosure of Your personal information{" "}
              <br />
              when You use the Application or the Website and tells You about{" "}
              <br />
              Your privacy rights and how the law protects You. Please read Our{" "}
              <br />
              Privacy Policy carefully before using Our Service
            </p>
          </div>
          <h1>Intellectual Property</h1>
          <hr className="line" />
          <div className="pragraphs">
            <p>
              The What Are You Feeling? App and its original content features{" "}
              <br />
              and functionality are and will remain the exclusive property of{" "}
              <br />
              the JIO Technology Limited a company registered in England and{" "}
              <br />
              Wales, and its licensors.
            </p>
            <p>
              The What Are You Feeling? App is protected by copyright, <br />
              trademark, and other laws of England without regard to any <br />
              conflict of law provisions of any jurisdiction. Both you and the{" "}
              <br />
              What Are You Feeling? App agree to submit to the exclusive <br />
              jurisdiction{" "}
            </p>
            <p>
              of the English courts, except that What Are You Feeling? may seek{" "}
              <br />
              injunctive relief in any jurisdiction in order to enforce its{" "}
              <br />
              rights under these terms
            </p>
            <p>
              Our trademarks and trade dress may not be used in connection with{" "}
              <br />
              any product or service without the prior written consent of JIO{" "}
              <br />
              Technology Limited
            </p>
          </div>
          <h1>Links to Other Websites</h1>
          <hr className="line" />
          <div className="pragraphs">
            <p>
              Our Service may contain links to third-party web sites or services{" "}
              <br />
              that are not owned or controlled by JIO Technology Limited.
            </p>
            <p>
              The Company has no control over, and assumes no responsibility{" "}
              <br />
              for, the content, privacy policies, or practices of any third{" "}
              <br />
              party web sites or services. You further acknowledge and agree{" "}
              <br />
              that JIO Technology Limited shall not be responsible or liable,{" "}
              <br />
              directly or indirectly, for any damage or loss caused or alleged{" "}
              <br />
              to be caused by or in connection with the use of or reliance on{" "}
              <br />
              any such content, goods or services available on or through any{" "}
              <br />
              such web sites or services.
            </p>
            <p>
              We strongly advise You to read the terms and conditions and <br />
              privacy policies of any third-party web sites or services that You{" "}
              <br />
              visit.
            </p>
          </div>
          <h1>Termination</h1>
          <hr className="line" />
          <div className="pragraphs">
            <p>
              We may terminate or suspend Your access immediately, without prior{" "}
              <br />
              notice or liability, for any reason whatsoever, including without{" "}
              <br />
              limitation if You breach these Terms and Conditions.
            </p>
            <p>
              Upon termination, Your right to use the Service will cease
              immediately.
            </p>
          </div>
          <h1>Limitation of Liability</h1>
          <hr className="line" />
          <div className="pragraphs">
            <p>
              To the maximum extent permitted by applicable law, in no event{" "}
              <br />
              shall the JIO Technology Limited or its suppliers be liable for{" "}
              <br />
              any special, incidental, indirect, or consequential damages <br />
              whatsoever (including, but not limited to, damages for loss of{" "}
              <br />
              profits, loss of data or other information, for business <br />
              interruption, for personal injury, loss of privacy arising out of{" "}
              <br />
              or in any way related to the use of or inability to use the <br />
              Service, third-party software and/or third-party hardware used{" "}
              <br />
              with the{" "}
            </p>
            <p>
              Service, or otherwise in connection with any provision of this{" "}
              <br />
              Terms), even if the Company or any supplier has been advised of{" "}
              <br />
              the possibility of such damages and even if the remedy fails of{" "}
              <br />
              its essential purpose.
            </p>
            <p>
              Notwithstanding any damages that You might incur, the entire{" "}
              <br />
              liability of the Company and any of its suppliers under any <br />
              provision of this Terms and Your exclusive remedy for all of the{" "}
              <br />
              foregoing shall be limited to the amount actually paid by You{" "}
              <br />
              through the What Are You Feeling? App.
            </p>
          </div>
          <h1>"AS IS" and "AS AVAILABLE" Disclaimer</h1>
          <hr className="line" />
          <div className="pragraphs">
            <p>
              The What Are You Feeling? App is provided to You "AS IS" and "AS{" "}
              <br />
              AVAILABLE" and with all faults and defects without warranty of any{" "}
              <br />
              kind. To the maximum extent permitted under applicable law, the{" "}
              <br />
              JIO Technology Limited, on its own behalf and on behalf of its{" "}
              <br />
              Affiliates and its and their respective licensors and service{" "}
              <br />
              providers, expressly disclaims all warranties, whether express,{" "}
              <br />
              implied, statutory or otherwise, with respect to the Service,{" "}
              <br />
              including all implied warranties of merchantability, fitness for a{" "}
              <br />
              particular purpose, title and non-infringement, and warranties{" "}
              <br />
              that may arise out of course of dealing, course of performance,{" "}
              <br />
              usage or trade practice. Without limitation to the foregoing, the{" "}
              <br />
              Company provides no warranty or undertaking, and makes no <br />
              representation of any kind that the What Are You Feeling App? will{" "}
              <br />
              meet Your requirements, achieve any intended results, be <br />
              compatible or work with any other software, applications, systems{" "}
              <br />
              or services, operate without interruption, meet any performance or{" "}
              <br />
              reliability standards or be error free or that any errors or{" "}
              <br />
              defects can or will be corrected.
            </p>
            <p>
              Without limiting the foregoing, neither the Company nor any of the
              <br />
              company's provider makes any representation or warranty of any
              <br />
              kind, express or implied: (i) as to the operation or availability
              <br />
              of the Service, or the information, content, and materials or
              <br />
              products included thereon; (ii) that the Service will be
              <br />
              uninterrupted or error-free; (iii) as to the accuracy,
              <br />
              reliability, or currency of any information or content provided
              <br />
              through the Service; or (iv) that the Service, its servers, the
              <br />
              content, or e-mails sent from or on behalf of the Company are free
              <br />
              of viruses, scripts, trojan horses, worms, malware, timebombs or
              <br />
              other harmful components.
            </p>
            <p>
              Some jurisdictions do not allow the exclusion of certain types of{" "}
              <br />
              warranties or limitations on applicable statutory rights of a{" "}
              <br />
              consumer, so some or all of the above exclusions and limitations{" "}
              <br />
              may not apply to You. But in such a case the exclusions and <br />
              limitations set forth in this section shall be applied to the{" "}
              <br />
              greatest extent enforceable under applicable law.
            </p>
          </div>
          <h1>Governing Law</h1>
          <hr className="line" />
          <div className="pragraphs">
            <p>
              The laws of England, excluding its conflicts of law rules, shall{" "}
              <br />
              govern this Terms and Your use of the Service. Your use of the{" "}
              <br />
              Application may also be subject to other local, state, national,{" "}
              <br />
              or international laws.
            </p>
          </div>
          <h1>Disputes Resolution</h1>
          <hr className="line" />
          <div className="pragraphs">
            <p>
              If You have any concern or dispute about the Service, You agree to{" "}
              <br />
              first try to resolve the dispute informally by contacting JIO{" "}
              <br />
              Technology Limited.
            </p>
          </div>
          <h1>For European Union (EU) Users</h1>
          <hr className="line" />
          <div className="pragraphs">
            <p>
              If You are a European Union consumer, you will benefit from any{" "}
              <br />
              mandatory provisions of the law of the country in which you are{" "}
              <br />
              resident in.
            </p>
          </div>
          <h1>Severability and Waiver</h1>
          <hr className="line" />
          <div className="pragraphs">
            <div>
              <h4>Severability</h4>
              <p>
                If any provision of these Terms is held to be unenforceable or{" "}
                <br />
                invalid, such provision will be changed and interpreted to{" "}
                <br />
                accomplish the objectives of such provision to the greatest
                extent <br />
                possible under applicable law and the remaining provisions will{" "}
                <br />
                continue in full force and effect.
              </p>
            </div>
            <div>
              <h4>Waiver</h4>
              <p>
                Except as provided herein, the failure to exercise a right or to{" "}
                <br />
                require performance of an obligation under these Terms shall not{" "}
                <br />
                effect a party's ability to exercise such
              </p>
              <p>
                right or require such performance at any time thereafter nor{" "}
                <br />
                shall the waiver of a breach constitute a waiver of any <br />
                subsequent breach.
              </p>
            </div>
          </div>
          <h1>Translation Interpretation</h1>
          <hr className="line" />
          <div className="pragraphs">
            <p>
              These Terms and Conditions may have been translated if We have{" "}
              <br />
              made them available to You on the What Are You Feeling? App. You{" "}
              <br />
              agree that the original English text shall prevail in the case of{" "}
              <br />a dispute.
            </p>
          </div>
          <h1>Changes to These Terms and Conditions</h1>
          <hr className="line" />
          <div className="pragraphs">
            <p>
              We reserve the right, at Our sole discretion, to modify or replace{" "}
              <br />
              these Terms at any time. If a revision is material We will make{" "}
              <br />
              reasonable efforts to provide at least 30 days' notice prior to{" "}
              <br />
              any new terms taking effect. What constitutes a material change{" "}
              <br />
              will be determined at Our sole discretion.
            </p>
            <p>
              By continuing to access or use Our Service after those revisions <br />
              become effective, You agree to be bound by the revised terms. If <br />
              You do not agree to the new terms, in whole or in part, please <br />
              stop using the website and the Service.
            </p>
          </div>
          <h1>Contact Us</h1>
          <hr className="line" />
          <div className="pragraphs">
            <p>If you have any questions about these Terms and Conditions, You can contact us:</p>
            <p>• By email: <a href="mailto:jio@wayfmeals.com">jio@wayfmeals.com</a></p>
            <p>Or Follow us on Twitter:</p>
            <p>• @_WAYF_</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TermandCondition;
