import React, { useContext } from "react";
import "./HandorCutlery.css";
import Button from "../../../Components/Button/Button";
import Wrapper from "../../../Components/Wrapper/Wrapper";
import { useNavigate } from "react-router-dom";
import cutlery from "../../../Assets/Images/PNG/cutlary.png";
import hand from "../../../Assets/Images/PNG/khanihands.png";
import { Data } from "../../../Storage/Context-api";
import Lottie from "lottie-react";
import {motion} from 'framer-motion'


const HandorCutlery = () => {
  const choiceCtx = useContext(Data);

  const HandsdataGiver = () => {
    choiceCtx.handsOrCutHandler("Hands");
    navigate("/SweetorSavory");
  };
  const CutlerydataGiver = () => {
    choiceCtx.handsOrCutHandler("Cutlery");
    navigate("/SweetorSavory");
  };

  const navigate = useNavigate();
  return (
    <div>
      <Wrapper tittle={"Hands or Cutlery?"} className="wbgcolororange">
        <div className="cutlerybtn">
          {/* <button onClick={HandsdataGiver}>Hands <img loading="lazy" src={hand} alt="" /></button>
          <button onClick={CutlerydataGiver}>Cutlery <img loading="lazy"  src={cutlery} alt="" /></button> */}
          <motion.button
           initial={{
        scale: 0.4
       }}
       whileInView={{
        scale: 1
       }}
       transition={{
        duration: 0.3
       }}
           onClick={HandsdataGiver}>
            Hands{" "}
            <Lottie
              autoplay={true}
              loop={true}
              animationData={require("../../../Components/Loading/hands.json")}
            />
          </motion.button>
          <motion.button
             initial={{
        scale: 0.4
       }}
       whileInView={{
        scale: 1
       }}
       transition={{
        duration: 0.3
       }}
          
           onClick={CutlerydataGiver}>
            Cutlery{" "}
            <Lottie
              autoplay={true}
              loop={true}
              animationData={require("../../../Components/Loading/cutlery.json")}
            />
          </motion.button>
        </div>
      </Wrapper>
    </div>
  );
};

export default HandorCutlery;
