import React, {useContext, useEffect} from 'react'
import './BigmealorLight.css';
import Button from "../../../Components/Button/Button";
import Wrapper from "../../../Components/Wrapper/Wrapper";
import { useNavigate } from "react-router-dom";
import { Data } from "../../../Storage/Context-api";



const BigmealorLight = () => {

  const choiceCtx = useContext(Data);

  const BigMealDatGiver = () => {
    choiceCtx.bigMealOrLightHandler('BigMeal');
    navigate('/SideDesertNeitherBoth');
  };
  const LightMealDataGiver = () => {
    choiceCtx.bigMealOrLightHandler('LightMeal');
    navigate('/SideDesertNeitherBoth');
  };

    const navigate = useNavigate()
    
  return (
    <div>
    <Wrapper tittle={"Big Meal or Light Meal?"} className="wbgcolorgreen">
        <div className="Bigmealbtn">
          <Button onClick={BigMealDatGiver}>Big Meal</Button>
          <Button onClick={LightMealDataGiver}>Light Meal</Button>        
          </div>
      </Wrapper>
        
    </div>
  )
}

export default BigmealorLight