import "./App.css";
// import { colors } from "./Components/Colors/Color";
import SingleOrgroup from "./Pages/Screens/SingleOrgroup/SingleOrgroup";
import Splash from "./Pages/Screens/Splash/Splash";
import { Navigate, Route, Routes } from "react-router-dom";
import Group from "./Pages/Screens/Group/Group";
import BreakLunchDinnner from "./Pages/Screens/BreakLunchDinner/BreakLunchDinnner";
import HandorCutlery from "./Pages/Screens/HandorCutlery/HandorCutlery";
import SweetorSavory from "./Pages/Screens/SweetorSavory/SweetorSavory";
import Setting from "./Pages/Screens/Settings/Setting";
import ContactUs from "./Pages/Screens/Settings/ContactUs/ContactUs";
import SpicyOrmild from "./Pages/Screens/SpicyOrM/SpicyOrmild";
import Advanture from "./Pages/Screens/Advanture/Advanture";
import BigmealorLight from "./Pages/Screens/BigmealorLight/BigmealorLight";
import Sides from "./Pages/Screens/Last/Sides";
import Storage from "./Storage/Context-api";
import Result from "./Pages/Screens/Result/Result";
import TermandCondition from "./Pages/Screens/Settings/TermsandCondition/TermandCondition";
import FinalPage from "./Pages/Screens/FinalPage/FinalPage";
import GoogleAds from "./Components/AdSense/GoogleAds";
// import Home from "./Pages/Screens/Home/Home";
// import SeemLike from "./Pages/Screens/SeemLike/SeemLike";



function App() {
  return (
  <Storage>
    <Routes>
      <Route
        path="/"
        element={<Navigate to={"/WhatAreYouFeeling"} replace />}
      />
      <Route path="/WhatAreYouFeeling" element={<Splash/>} />
      <Route path="/SingleOrGroup" element={<SingleOrgroup />} />
      <Route path="/Group" element={<Group />} />
      <Route path="/BreakLunchDinner" element={<BreakLunchDinnner />} />
      <Route path="/HandorCutlery" element={<HandorCutlery />} />
      <Route path="/SweetorSavory" element={<SweetorSavory />} />
      <Route path="/Settings" element={<Setting />} />
      <Route path="/ContactUs" element={<ContactUs />} />
      <Route path="/TermsandCondition" element={<TermandCondition />} />
      <Route path="/SpicyorMild" element={<SpicyOrmild />} />
      <Route path="/AdventureorReliable" element={<Advanture/>} />
      <Route path="/Bigmealorlightmeal" element={<BigmealorLight/>} />
      <Route path="/SideDesertNeitherBoth" element={<Sides/>} />
      <Route path="/Result" element={<Result/>} />
      <Route path="/Final" element={<FinalPage/>} />
    </Routes>
    {/* <GoogleAds/> */}
    </Storage>
  );
}

export default App;
