import React from 'react'
import './Setting.css';
import backarrow from '../../../Assets/Images/Others/backarrow.png';
import arrow from '../../../Assets/Images/PNG/arrow.png'
import { useNavigate } from 'react-router-dom';
import {motion} from 'framer-motion'


const Setting = () => {
    
    const navigate = useNavigate()

  return (
    <div>
      <div className="headsetting">
      <div className="settingback">
         <div className="setting">
            <img src={backarrow} alt="" onClick={()=> navigate("/WhatAreYouFeeling")}/>
            <h2>Settings</h2>
         </div>   
      </div>
        <div className="settingbtn">
            <motion.button
            initial={{
       opacity: 0, x: -100
       }}
       whileInView={{
       opacity: 1, x: 1
       }}
       transition={{
        duration: 0.5
       }}
             onClick={()=> navigate("/TermsandCondition")} >Terms and Conditions <img src={arrow} alt="" /></motion.button>
            <motion.button
            initial={{
              opacity: 0, x: 100
       }}
       whileInView={{
        opacity: 1, x: 1
       }}
       transition={{
        duration: 0.5
       }}
             onClick={()=> navigate("/ContactUs")}>Contact Us<img src={arrow} alt="" /></motion.button>
        </div>
      </div>
    </div>
  )
}

export default Setting