import React from 'react'
import './Group.css';
import Button from '../../../Components/Button/Button';
import Wrapper from '../../../Components/Wrapper/Wrapper';
import { useNavigate } from 'react-router-dom';
import {motion} from 'framer-motion'


const Group = () => {

  const navigate = useNavigate()
  
  return (
    <div>
    <Wrapper tittle={"2, 3-5 , 6+ ?"} className="wbgcolororange">
    <div className="groupbtn">
        <Button  onClick={()=> navigate("/BreakLunchDinner")}>2</Button>
        <Button  onClick={()=> navigate("/BreakLunchDinner")}>3-5</Button>
        <Button  onClick={()=> navigate("/BreakLunchDinner")}>6+</Button>
    </div>
    </Wrapper>

    </div>
  )
}

export default Group