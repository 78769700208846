import { Data } from "../../../Storage/Context-api";
import React, { useContext, useEffect } from "react";
import SeemLike from "../SeemLike/SeemLike";
import { useState } from "react";
import ResultPage from "./ResultPage";
import files from "../../../Storage/filebase64";
import Pizza from "../../../Assets/Images/Results_Emoji/Pizza.png";
// import Indian from "../../../Assets/Images/Results_Emoji/Indian.png";
import Japanese from "../../../Assets/Images/Results_Emoji/Japanese.png";
import Cafe from "../../../Assets/Images/Results_Emoji/Cafe.png";
import { useNavigate } from "react-router-dom";
import { Images } from "../../../FinalResultImages";

const Result = () => {
  const { Indian } = Images;
  const navigate = useNavigate();
  const [screen, setScreen] = useState(<SeemLike />);
  const [lastimg, setlastimg] = useState("");
  const choiceCtx = useContext(Data);
  let FinalResult = "";
  let resultName = "";

  const SINGLEORGROUP = choiceCtx.singleOrGroup;
  const MEAL = choiceCtx.meal;
  const HANDORCUT = choiceCtx.handsOrCut;
  const SPCICYORMILD = choiceCtx.spicyOrMild;
  const SWEETORSAVORY = choiceCtx.sweetOrSav;
  const ADVANORRELI = choiceCtx.adventurousOrRel;
  const BIGORLIGHTMEAL = choiceCtx.bigMealOrLight;
  const SDNB = choiceCtx.sdnb;

  // ------------------------------------------------------------- ALL BREAKFAST -----------------------------------------------
  if (MEAL === "breackfast") {
    if (
      (HANDORCUT === "Hands" || HANDORCUT === "Cutlery") &&
      (SWEETORSAVORY === "Sweet" || SWEETORSAVORY === "Savory") &&
      (SPCICYORMILD === "Spicy" || SPCICYORMILD === "Mild") &&
      (ADVANORRELI === "Advanturous" || ADVANORRELI === "Reliable") &&
      (BIGORLIGHTMEAL === "BigMeal" || BIGORLIGHTMEAL === "LightMeal")
    ) {
      if (BIGORLIGHTMEAL === "LightMeal") {
        resultName = "Coffee Shop";
        FinalResult = require("../../../Assets/Images/Results_Emoji/Coffee.png");
        choiceCtx.ResultImageHandler(files.Coffee);
      } else if (BIGORLIGHTMEAL === "BigMeal") {
        resultName = "Cafe";
        FinalResult = Cafe;
        choiceCtx.ResultImageHandler(files.Cafe);
      }
    }
  }

  // ------------------------------------------------------------ ALL LUNCH -------------------------------------------------------
  else if (MEAL === "lunch") {
    if (ADVANORRELI === "Advanturous") {
      resultName = "Japanese";
      FinalResult = Japanese;
      choiceCtx.ResultImageHandler(files.japanese);
    } else {
      //this is reliable
      if (HANDORCUT === "Hands") {
        if (
          (SWEETORSAVORY === "Sweet" || SWEETORSAVORY === "Savory") &&
          SPCICYORMILD === "Mild"
        ) {
          if (BIGORLIGHTMEAL === "BigMeal") {
            if (ADVANORRELI === "Reliable") {
              resultName = "Burger";
              FinalResult = require("../../../Assets/Images/Results_Emoji/Burger.png");
              choiceCtx.ResultImageHandler(files.Burger);
            }
          } else if (HANDORCUT === "Hands") {
            if (SPCICYORMILD === "Mild") {
              if (ADVANORRELI === "Reliable") {
                if (BIGORLIGHTMEAL === "LightMeal") {
                  resultName = "Sandwich";
                  FinalResult = require("../../../Assets/Images/Results_Emoji/Sandwich.png");
                  choiceCtx.ResultImageHandler(files.Sandwich);
                }
              }
            }
          }
        } else {
          //thii is spicy
          if (BIGORLIGHTMEAL === "LightMeal") {
            resultName = "Sandwich";
            FinalResult = require("../../../Assets/Images/Results_Emoji/Sandwich.png");
            choiceCtx.ResultImageHandler(files.Sandwich);
          } else if (
            HANDORCUT === "Hands" &&
            (SWEETORSAVORY === "Sweet" || SWEETORSAVORY === "Savory")
          ) {
            // this is Bigmeal
            if (ADVANORRELI === "Reliable" && SPCICYORMILD === "Spicy") {
              if (BIGORLIGHTMEAL === "BigMeal") {
                resultName = "Chicken";
                FinalResult = require("../../../Assets/Images/Results_Emoji/Chicken.png");
                choiceCtx.ResultImageHandler(files.Chicken);
              }
            }
          }
        }
      } else {
        ///////////////////////lunch + reliable+ cutlery
        resultName = "Coffee Shop";
        FinalResult = require("../../../Assets/Images/Results_Emoji/Coffee.png");
        choiceCtx.ResultImageHandler(files.Coffee);
      }
    }
  }

  // --------------------------------------------------------------------ALL DINNER --------------------------------------------------------------------------
  else {
    if (HANDORCUT === "Cutlery") {
      if (SWEETORSAVORY === "Sweet") {
        if (SPCICYORMILD === "Mild") {
          if (ADVANORRELI === "Reliable") {
            if (SDNB === "Deserts" || SDNB === "Both") {
              resultName = "Deserts";
              FinalResult = require("../../../Assets/Images/Results_Emoji/Deserts.png");
              choiceCtx.ResultImageHandler(files.Deserts);
            } else {
              ///////////////////////Dinner + Cutlery + Sweet + Mild + Reliable + sides or neither
              //dessert
              resultName = "Deserts";
              FinalResult = require("../../../Assets/Images/Results_Emoji/Deserts.png");
              choiceCtx.ResultImageHandler(files.Deserts);
            }
          } else {
            ///////////////////////Dinner + Cutlery + Sweet + Mild + advanturous
            //kebab
            resultName = "Kebab";
            FinalResult = require("../../../Assets/Images/Results_Emoji/Kebab.png");
            choiceCtx.ResultImageHandler(files.Kebab);
          }
        } else {
          //this is spicy
          if (ADVANORRELI === "Reliable") {
            resultName = "Chinese";
            FinalResult = require("../../../Assets/Images/Results_Emoji/Chinese.png");
            choiceCtx.ResultImageHandler(files.Chinese);
          } else {
            ///////////////////////Dinner + Cutlery + Sweet + spicy + advanturous
            resultName = "Kebab";
            FinalResult = require("../../../Assets/Images/Results_Emoji/Kebab.png");
            choiceCtx.ResultImageHandler(files.Kebab);
          }
        }
      } else {
        //this is savory
        if (SPCICYORMILD === "Spicy") {
          if (ADVANORRELI === "Advanturous") {
            if (BIGORLIGHTMEAL === "BigMeal") {
              resultName = "Caribbean";
              FinalResult = require("../../../Assets/Images/Results_Emoji/Caribbean.png");
              choiceCtx.ResultImageHandler(files.Caribbean);
            } else {
              resultName = "Japanese";
              FinalResult = Japanese;
              choiceCtx.ResultImageHandler(files.japanese);
            }
          } else {
            //this is reliable
            resultName = "Indian";
            FinalResult = Indian;
            choiceCtx.ResultImageHandler(files.Indian);
          }
        } else {
          //thi is mild
          if (ADVANORRELI === "Advanturous") {
            if (BIGORLIGHTMEAL === "LightMeal") {
              resultName = "Japanese";
              FinalResult = Japanese;
              choiceCtx.ResultImageHandler(files.japanese);
            } else {
              ///////////////////////Dinner + Cutlery + savory + mild + advanturous + BigMeal
              resultName = "Kebab";
              FinalResult = require("../../../Assets/Images/Results_Emoji/Kebab.png");
              choiceCtx.ResultImageHandler(files.Kebab);
            }
          } else {
            //this is reliable
            if (BIGORLIGHTMEAL === "BigMeal") {
              if (SDNB === "Sides" || SDNB === "Neither") {
                resultName = "Fish and Chips";
                // FinalResult = require(`../../../Assets/Images/Results_Emoji/FishnChips.png`);
                FinalResult = require("../../../Assets/Images/Results_Emoji/FishnChips.png");
                choiceCtx.ResultImageHandler(files.FishNChips);
              } else {
                //this is desert or both
                resultName = "Kebab";
                FinalResult = require("../../../Assets/Images/Results_Emoji/Kebab.png");
                choiceCtx.ResultImageHandler(files.Kebab);
              }
            } else {
              // this is lightmeal
              ///////////////////////Dinner + Cutlery + savory + mild + reliable + lightmeal
              resultName = "Japanese";
              FinalResult = Japanese;
              choiceCtx.ResultImageHandler(files.japanese);
            }
          }
        }
      }
    } else {
      //this is hands
      if (SWEETORSAVORY === "Sweet") {
        if (ADVANORRELI === "Advanturous") {
          resultName = "BBQ";
          FinalResult = require("../../../Assets/Images/Results_Emoji/BarBQ.png");
          choiceCtx.ResultImageHandler(files.BBq);
        } else {
          resultName = "Chicken";
          FinalResult = require("../../../Assets/Images/Results_Emoji/Chicken.png");
          choiceCtx.ResultImageHandler(files.Chicken);
        }
      } else {
        //this is savory
        if (SINGLEORGROUP === "Single") {
          if (SPCICYORMILD === "Mild") {
            resultName = "Burger";
            FinalResult = require("../../../Assets/Images/Results_Emoji/Burger.png");
            choiceCtx.ResultImageHandler(files.Burger);
          } else {
            // this is spicy part
            ///////////////////////Dinner + Single + Hands + Savory + spicy
            resultName = "Indian";
            FinalResult = Indian;
            choiceCtx.ResultImageHandler(files.Indian);
          }
        } else {
          //this is group
          if (SPCICYORMILD === "Mild") {
            resultName = "Pizza";
            // FinalResult = require("../../../Assets/Images/Result_Emoji/Pizza.png");
            FinalResult = Pizza;
            choiceCtx.ResultImageHandler(files.Pizza);
          } else {
            // this is spicy part
            ///////////////////////Dinner + group + Hands + Savory + spicy
            // resultName = 'REFRESH MAT KR NE BHAI RESULT CHANGE HO JATA HAI JA ABH WAPIS SE KR START';
            resultName = "Indian";
            // FinalResult = require("../../../Assets/Images/Result_Emoji/Indian.png");
            FinalResult = Indian;
            choiceCtx.ResultImageHandler(files.Indian);
          }
        }
      }
    }
  }

  // console.log(bohutkhain)
  // const khaini = { resultName, FinalResult };



  const khenisahab = Images[resultName];
  const [value, setvalue] = useState(resultName);
  
  
  const [lastresult, setlastresult] = useState({FinalResult, resultName});
  useEffect(() => {
    window.localStorage.setItem("result", JSON.stringify(lastresult));
  }, [lastresult]);

  // useEffect(()=> {   
  //   const bohutkhain = localStorage.getItem("result");
  //   if( bohutkhain !== null){
  //     setlastresult((prev) => ({...prev, ...bohutkhain}));
  //   }
  //   console.log(bohutkhain);
  // },[])
 
  useEffect(() => {
    const sss = "Cafe";
    if (value === "") {
      setvalue(sss);
    } else {
      setvalue(value);
    }
  });

  useEffect(() => {
    window.onbeforeunload = function() {
        return true;
    };

    return () => {
        window.onbeforeunload = null;
    };
}, []);

// useEffect(() => {
//   window.addEventListener("beforeunload", alertUser);
//   return () => {
//     window.removeEventListener("beforeunload", alertUser);
//   };
// }, []);
// const alertUser = (e) => {
//   e.preventDefault();
//   e.returnValue = "Result will be reset";
// };

    
   
  // -----------------------------------------------------------------------------------
  // -----------------------------------------------------------------------------------
  const gotoNextScreen = () => {
    navigate("FinalPage");
  };
  setTimeout(() => {
    choiceCtx.ResultHandler(resultName);
  }, 100);
  useEffect(() => {
    setTimeout(() => {
      setScreen(
        <>
          <ResultPage
            Result={khenisahab && khenisahab}
            name={resultName}
            onClick={gotoNextScreen}
          />
        </>
      );
    }, 2500);
  }, [Result]);
  return <>{screen}</>;
};

export default Result;
