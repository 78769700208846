import React, {useEffect } from "react";
// import { Data } from "../../../Storage/Context-api";
import  './SeemLike.css';
import Lottie from 'lottie-react';
import Wrapper from "../../../Components/Wrapper/Wrapper";


const SeemLike = ({}) => {
  // const choiceCtx = useContext(Data);
  
  return (
    <div>
    <Wrapper tittle={"Seems like you are feeling.."} className="wbgcoloryellow">
        <div className="loadingg">
         <Lottie
         autoplay={true}
         loop={true}
         animationData={require('../../../Components/Loading/loading.json')}
         >
         </Lottie>
        </div>
      </Wrapper>
    </div>
  )
}

export default SeemLike