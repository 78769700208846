import React from 'react'
import  './Wrapper.css';

const Wrapper = ({tittle,children, className }) => {
  return (
    <div>
        <div className="wrappercontainer">
            <div className="heading">
                <h1>What Are You Feeling?</h1>
            <div className="tittle">
                <h2>{tittle}</h2>
            </div>
            </div>
            <div className={className} >
            {children}
            </div>
        </div>
    </div>
  )
}

export default Wrapper