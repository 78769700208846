import { useNavigate } from 'react-router-dom';
import settingdot from "../../../Assets/Images/Others/settingeDot.png";
import logo from "../../../Assets/Images/Others/logo.png";
import {motion} from 'framer-motion'



const Home = () => {
    const navigate = useNavigate()

    
  return (
    <div>
      <div className="splashcontainer">
        <div className="dots">
          <img src={settingdot} onClick={() => navigate("/Settings")} alt="dots" />
        </div>
        <motion.div
        initial={{scale: 2, y: 100}}
        whileInView={{scale: 1, y: -143, }}
        transition={{duration: 1}}
         className="logo2">
          <motion.img
           src={logo} alt="" />
        </motion.div>
      </div>
    </div>
  )
}

export default Home