import React from 'react'
import "./Splash.css";
import logo from "../../../Assets/Images/Others/logo.png";
import settingdot from "../../../Assets/Images/Others/settingeDot.png";
import { motion } from "framer-motion";
import appicon from '../../../Assets/Images/PNG/appstore.png'
import gicon from '../../../Assets/Images/PNG/gplay.png'
import { Link, useNavigate } from "react-router-dom";
import { useEffect } from 'react';

const Splash2 = () => {
    const navigate = useNavigate();

    useEffect(()=> {
        localStorage.removeItem("result")
    },[])

  return (
    <div>
     <div className='mainsplashdiv'>
      <div className="splashcontainer">
        <motion.div
        
        className="dots">
          <img src={settingdot} onClick={() => navigate("/Settings")} />
        </motion.div>
        <div className="logo">
          <img src={logo} alt="" />
        </div>
        <motion.div
          initial={{
            opacity: 0,
            x: -100,
          }}
          whileInView={{
            opacity: 1,
            x: 1,
          }}
          transition={{ duration: 0.8 }}
          className="splashheading"
        >
          <h1>What Are You Feeling?</h1>
        </motion.div>
        <motion.div
            initial={{
            opacity: 0,
            x: 100,
          }}
          whileInView={{
            opacity: 1,
            x: 1,
          }}
          transition={{ duration: 0.8 }}
         className="splashbtn">
          <button onClick={() => navigate("/SingleOrgroup")}>START</button>
        </motion.div>
        <div className="socialbtnss">
          <a href='https://apps.apple.com/in/app/what-are-you-feeling/id6443659157' target={"_blank"}>
            <img src={appicon} alt="" />
          </a>
          <a href='https://play.google.com/store/apps/details?id=com.amin.wryf' target={"_blank"}>
            <img src={gicon} alt="" />
          </a>
        </div>
      </div>
    </div>
    </div>
  )
}

export default Splash2