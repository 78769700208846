import React from 'react'
import './Button.css';
import {motion} from 'framer-motion'


const Button = ({children, onClick}) => {
  return (
    <div className='mainbtndiv'>
    <motion.button
       initial={{
        scale: 0.4
       }}
       whileInView={{
        scale: 1
       }}
       transition={{
        duration: 0.3
       }}
     onClick={onClick}>{children}</motion.button>
    </div>
  )
}

export default Button