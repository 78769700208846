import React from "react";
import "./Advanture.css";
import {useContext} from 'react';
import Button from "../../../Components/Button/Button";
import Wrapper from "../../../Components/Wrapper/Wrapper";
import { useNavigate } from "react-router-dom";
import { Data } from "../../../Storage/Context-api";

const Advanture = () => {
  const choiceCtx = useContext(Data);
  const navigate = useNavigate()

  const AdvanturousDataGiver = () => {
    choiceCtx.adventurousOrRelHandler('Advanturous');
    navigate('/Bigmealorlightmeal');
  };
  const ReliableDataGiver = () => {
    choiceCtx.adventurousOrRelHandler('Reliable');
    navigate('/Bigmealorlightmeal');
  };

  return (
    <div>
      <Wrapper tittle={"Adventurous or Reliable?"} className="wbgcolororange">
        <div className="Advanturebtn">
          <Button onClick={AdvanturousDataGiver}>Adventurous</Button>
          <Button onClick={ReliableDataGiver}>Reliable</Button>        
          </div>
      </Wrapper>
    </div>
  );
};

export default Advanture;
