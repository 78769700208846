import React from "react";
import "./FinalPage.css";
import { useNavigate } from "react-router-dom";
import settingdot from "../../../Assets/Images/Others/settingeDot.png";
import Lottie from "lottie-react";

const FinalPage = () => {
  const navigate = useNavigate();

  return (
    <div>
      <div className="Finalcontainer">
        <div className="dotss">
          <img src={settingdot} onClick={() => navigate("/Settings")} alt="" />
        </div>
        <div className="Finalheading">
          <h1>Were We?</h1>
        </div>
        <div className="spoton">
          <div className="spot">
            <h2>Spot On!</h2>
            <Lottie
              autoplay={true}
              loop={true}
              animationData={require("../../../Components/Loading/emojisecond.json")}
            ></Lottie>
          </div>
          <div className="not">
            <h2>Not Quite Right...</h2>
            <Lottie
              autoplay={true}
              loop={true}
              animationData={require("../../../Components/Loading/emojithird.json")}
            ></Lottie>
          </div>
        </div>
        <div className="Finalbtn">
          <button onClick={() => navigate("/WhatAreYouFeeling")}>
            Till Next Time
          </button>
          <button onClick={() => navigate("/WhatAreYouFeeling")}>Try Again!</button>
        </div>
      </div>
    </div>
  );
};

export default FinalPage;
