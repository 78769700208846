import React, {useContext, useEffect} from "react";
import './Sides.css';
import Wrapper from "../../../Components/Wrapper/Wrapper";
import { useNavigate } from "react-router-dom";
import sides from '../../../Assets/Images/PNG/sides.png'
import cake from '../../../Assets/Images/PNG/cake.png'
import cross from '../../../Assets/Images/PNG/cross.png'
import thumb from '../../../Assets/Images/PNG/thumb.png'
import { Data } from "../../../Storage/Context-api";
import Lottie from "lottie-react";
import {motion} from 'framer-motion'



const Sides = () => {

  const choiceCtx = useContext(Data);

  const name = choiceCtx.Result






  const SidesDataGiver = () => {
    choiceCtx.sdnbHandler('Sides');
    navigate('/Result');
  };
  const DesertsDataGiver = () => {
    choiceCtx.sdnbHandler('Deserts');
    navigate('/Result');
  };
  const NeitherhDataGiver = () => {
    choiceCtx.sdnbHandler('Neither');
    navigate('/Result');
  };

  const BothDataGiver = () => {
    choiceCtx.sdnbHandler('Both');
    navigate('/Result');
  };

    const navigate = useNavigate()

  return (
    <div>
    <Wrapper tittle={"Sides, Deserts, Neither or Both?"} className="wbgcoloryellow">
        <div className="sidebtn">
          <motion.button
           initial={{
        scale: 0.4
       }}
       whileInView={{
        scale: 1
       }}
       transition={{
        duration: 0.3
       }}
           onClick={SidesDataGiver}>Sides <img className='img1' loading="lazy" src={sides} alt="" /></motion.button>
          <motion.button
           initial={{
        scale: 0.4
       }}
       whileInView={{
        scale: 1
       }}
       transition={{
        duration: 0.3
       }}
           onClick={DesertsDataGiver}>Deserts <img className='img2' loading="lazy"  src={cake} alt="" /></motion.button>
          <motion.button
           initial={{
        scale: 0.4
       }}
       whileInView={{
        scale: 1
       }}
       transition={{
        duration: 0.3
       }}
           onClick={NeitherhDataGiver}>Neither <img className='img3' loading="lazy"  src={cross} alt="" /></motion.button>
          <motion.button
           initial={{
        scale: 0.4
       }}
       whileInView={{
        scale: 1
       }}
       transition={{
        duration: 0.3
       }}
           onClick={BothDataGiver}>Both <img className='img4' loading="lazy"  src={thumb} alt="" /></motion.button>

          {/* <motion.button
             initial={{
        scale: 0.4
       }}
       whileInView={{
        scale: 1
       }}
       transition={{
        duration: 0.3
       }}
           onClick={SidesDataGiver}>Sides  <Lottie
              autoplay={true}
              loop={true}
              animationData={require("../../../Components/Loading/salad.json")}
            ></Lottie> </motion.button>
          <motion.button
             initial={{
        scale: 0.4
       }}
       whileInView={{
        scale: 1
       }}
       transition={{
        duration: 0.3
       }}
           onClick={DesertsDataGiver}>Deserts  <Lottie
              autoplay={true}
              loop={true}
              animationData={require("../../../Components/Loading/cake.json")}
            ></Lottie> </motion.button>
          <motion.button
             initial={{
        scale: 0.4
       }}
       whileInView={{
        scale: 1
       }}
       transition={{
        duration: 0.3
       }}
           onClick={NeitherhDataGiver}>Neither  <Lottie
              autoplay={true}
              loop={true}
              animationData={require("../../../Components/Loading/cross.json")}
            ></Lottie>  </motion.button>
          <motion.button
             initial={{
        scale: 0.4
       }}
       whileInView={{
        scale: 1
       }}
       transition={{
        duration: 0.3
       }}
           onClick={BothDataGiver}>Both  <Lottie
              autoplay={true}
              loop={true}
              animationData={require("../../../Components/Loading/thump.json")}
            ></Lottie>  </motion.button> */}
        </div>
      </Wrapper>

    </div>
  )
}

export default Sides