import React, {useContext} from "react";
import Wrapper from '../../../Components/Wrapper/Wrapper';
import './SingleOrgroup.css';
import { useNavigate } from 'react-router-dom';
import { Data } from "../../../Storage/Context-api";
import Lottie from "lottie-react";
import {motion} from 'framer-motion'


const SingleOrgroup = () => {
  const choiceCtx = useContext(Data);


  const single = () => {
    choiceCtx.singleOrGroupHandler('Single');
    navigate('/BreakLunchDinner');
  };
  const group = () => {
    choiceCtx.singleOrGroupHandler('Group');
    navigate('/Group');
  };


    const navigate = useNavigate()
  return (
    <div>
    <Wrapper tittle={"Single Or Group?"} className="wbgcolorgreen">
      <div className="singlegroup">
       {/* <button onClick={single}>Single <img  src={singleimg} alt="" /> </button>
       <button onClick={group}>Group <img  src={groupimg} alt="" /></button> */}
       <motion.button
       initial={{
        scale: 0.4
       }}
       whileInView={{
        scale: 1
       }}
       transition={{
        duration: 0.3
       }}
        onClick={single}>Single <Lottie
         autoplay={true}
              loop={true}
              animationData={require("../../../Components/Loading/single.json")}
       /> </motion.button>
       <motion.button
        initial={{
        scale: 0.4
       }}
       whileInView={{
        scale: 1
       }}
       transition={{
        duration: 0.3
       }}
        onClick={group}>Group <Lottie
        autoplay={true}
              loop={true}
              animationData={require("../../../Components/Loading/single.json")}
       /> <Lottie
       autoplay={true}
              loop={true}
              animationData={require("../../../Components/Loading/single.json")}
       />
       <Lottie
        autoplay={true}
              loop={true}
              animationData={require("../../../Components/Loading/single.json")}
       />
       </motion.button>
      </div>
      {/* <GoogleAds/> */}
    </Wrapper>
    </div>
  )
}

export default SingleOrgroup