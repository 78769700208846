import React, {useContext} from "react";
import "./SweetorSavory.css";
import Button from "../../../Components/Button/Button";
import Wrapper from "../../../Components/Wrapper/Wrapper";
import { useNavigate } from "react-router-dom";
import { Data } from "../../../Storage/Context-api";
import {motion} from 'framer-motion'



const SweetorSavory = () => {
  const choiceCtx = useContext(Data);

  const SweetDataGiver = () => {
    choiceCtx.sweetOrSavHandler('Sweet');
    navigate('/SpicyorMild');
  };
  const Savory = () => {
    choiceCtx.sweetOrSavHandler('Savory');
    navigate('/SpicyorMild');
  };

    const navigate = useNavigate();

  return (
    <div>
      <Wrapper tittle={"Sweet or Savory?"} className="wbgcolorgreen">
        <div className="sweetbtn">
          <Button onClick={SweetDataGiver}>Sweet</Button>
          <Button onClick={Savory}>Savory</Button>
        </div>
      </Wrapper>
    </div>
  );
};

export default SweetorSavory;
