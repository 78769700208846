import React, {useContext} from "react";
import './BreakLunnchDinner.css';
import Button from '../../../Components/Button/Button';
import Wrapper from '../../../Components/Wrapper/Wrapper';
import { useNavigate } from 'react-router-dom';
import { Data } from "../../../Storage/Context-api";
import {motion} from 'framer-motion'



const BreakLunchDinnner = () => {
  const choiceCtx = useContext(Data);

  
  const BreackFast = () => {
    choiceCtx.mealHandler('breackfast');
    navigate('/HandorCutlery');
  };
  const Lunch = () => {
    choiceCtx.mealHandler('lunch');
    navigate('/HandorCutlery');
  };
  const Dinner = () => {
    choiceCtx.mealHandler('dinner');
    navigate('/HandorCutlery');
  };

  const navigate = useNavigate()
  return (
    <div>
<Wrapper tittle={"Breakfast, Lunch, or, Dinner"} className="wbgcoloryellow">
    <div className="Breakbtn">
        <Button onClick={BreackFast}>Breakfast</Button>
        <Button onClick={Lunch}>Lunch</Button>
        <Button onClick={Dinner}>Dinner</Button>
    </div>
    </Wrapper>
    </div>
  )
}

export default BreakLunchDinnner