import React, {useContext} from "react";
import './SpicyOrmild.css';
import Wrapper from "../../../Components/Wrapper/Wrapper";
import { useNavigate } from "react-router-dom";
import spicy from '../../../Assets/Images/PNG/spicy.png';
import mild from '../../../Assets/Images/PNG/mild.png';
import { Data } from "../../../Storage/Context-api";
import Lottie from "lottie-react";
import {motion} from 'framer-motion'





const SpicyOrmild = () => {
  const choiceCtx = useContext(Data);

  const SpicyDataGiver = () => {
    choiceCtx.spicyOrMildHandler('Spicy');
    navigate('/AdventureorReliable');
  };
  const MildDataGiver = () => {
    choiceCtx.spicyOrMildHandler('Mild');
    navigate('/AdventureorReliable');
  };

  const navigate = useNavigate()
  return (
    <div>
       <Wrapper tittle={"Spicy or Mild?"} className="wbgcoloryellow">
        <div className="Spicybtn">
          <motion.button
           initial={{
        scale: 0.4
       }}
       whileInView={{
        scale: 1
       }}
       transition={{
        duration: 0.3
       }}
           onClick={SpicyDataGiver}>Spicy <img className="spicy" loading="lazy" src={spicy} alt="" /></motion.button>
          {/* <button onClick={MildDataGiver}>Mild<img loading="lazy"  src={mild} alt="" /></button> */}
          <motion.button
           initial={{
        scale: 0.4
       }}
       whileInView={{
        scale: 1
       }}
       transition={{
        duration: 0.3
       }}
           onClick={MildDataGiver}>Mild <Lottie
           autoplay={true}
              loop={true}
              animationData={require("../../../Components/Loading/yummyemoji.json")}
          >

          </Lottie></motion.button>
        </div>
      </Wrapper>

    </div>
  )
}

export default SpicyOrmild