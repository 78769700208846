import React from 'react'
import './ContactUs.css';
import backarrow from '../../../../Assets/Images/Others/backarrow.png'
import Email from '../../../../Assets/Images/PNG/Email.png'
import twitter from '../../../../Assets/Images/PNG/twitter.png'

import { Link, useNavigate } from 'react-router-dom';


const ContactUs = () => {

    const navigate = useNavigate()

  return (
    <div>
        <div className="contactus">
      <div className="contactusback">
         <div className="contacthead">
            <img src={backarrow} alt="" onClick={()=> navigate("/Settings")}/>
            <h2>Contact Us</h2>
         </div>
      </div>
      <div className="pra">
        <p>If you have any questions or enquiries, you contact us via:</p>
      </div>
        <div className="ContactUsbtn">
            <a href='mailto:jio@wayfmeals.com' > <img  loading='lazy' src={Email} alt="" />By Email</a>
            <a href="https://twitter.com/_WAYF_" target="_wsexw"> <img loading='lazy'  src={twitter} alt="" />Follow us on Twitter</a>
        </div>
      </div>
    </div>
  )
}

export default ContactUs