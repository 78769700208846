import React, { useEffect, useState } from "react";
import "./Splash.css";
import Home from "../Home/Home";
import Splash2 from "./Splash2";

const Splash = () => {
  const [screen, setScreen] = useState(<Home/>)
  useEffect(()=> {
          setTimeout(()=> {
                  setScreen(<Splash2/>)
          }, 1000)
  },[Splash])
  
  return (
    <>
     {screen}
    </>
   
  );
};

export default Splash;
