import {createContext, useState} from 'react';
import React from 'react';

const Data = createContext({
  singleOrGroup: '',
  singleOrGroupHandler: () => {},

  meal: '',
  mealHandler: () => {},

  handsOrCut: '',
  handsOrCutHandler: () => {},

  sweetOrSav: '',
  sweetOrSavHandler: () => {},

  spicyOrMild: '',
  spicyOrMildHandler: () => {},

  adventurousOrRel: '',
  adventurousOrRelHandler: () => {},

  bigMealOrLight: '',
  bigMealOrLightHandler: () => {},

  sdnb: '',
  sdnbHandler: () => {},

  Result: '',
  ResultHandler: () => {},

  ResultImage: '',
  ResultImageHandler: () => {},
});

const Storage = ({children}) => {
  // --------------------------------------------------------------S I N G L E O R G R O U P ------------------------------------------------------------------------
  const [singleOrGroupdata, setSingleOrGroupdata] = useState('');
  const singleOrGroupHandler = data => {
    setSingleOrGroupdata(()=> data);
  };

  // -----------------------------------------------------------M E A L-------------------------------------------------------------------
  const [mealdata, setMealdata] = useState('');
  const mealdataHandler = data => {
    setMealdata(data);
  };

  // -----------------------------------------------------------H A N D S O R C U T L E R Y-------------------------------------------------------------------
  const [handsOrCutdata, setHandsOrCutdata] = useState('');
  const handsOrCutdataHandler = data => {
    setHandsOrCutdata(()=>data);
  };

  // -----------------------------------------------------------s w e e t o r s a v o r y-------------------------------------------------------------------
  const [sweetOrSavdata, setSweetOrSavdata] = useState('');
  const sweetOrSavdataHandler = data => {
    setSweetOrSavdata(()=>data);
  };

  // -----------------------------------------------------------S P I C Y O R M I L D------------------------------------------------------------------
  const [spicyOrMilddata, setSpicyOrMilddata] = useState('');
  const spicyOrMilddataHandler = data => {
    setSpicyOrMilddata(()=>data);
  };

  // -----------------------------------------------------------A D V A N T U R O U S O R R E L I A B L E------------------------------------------------------------------
  const [adventurousOrReldata, setAdventurousOrReldata] = useState('');
  const adventurousOrRelHandler = data => {
    setAdventurousOrReldata(()=>data);
  };

  // -----------------------------------------------------------B I G M E A L O R L I G H T M E A L ------------------------------------------------------------------
  const [bigMealOrLightdata, setBigMealOrLightdata] = useState('');
  const bigMealOrLightdataHandler = data => {
    setBigMealOrLightdata(()=>data);
  };

  // -----------------------------------------------------------S I D E S , D E S E R T S , N E I T H E R O R B O T H ------------------------------------------------------------------
  const [SDNBdata, setSDNBdata] = useState('');
  const SDNBdataHandler = data => {
    setSDNBdata(()=> data);
  };

  // -----------------------------------------------------------R E S U L T------------------------------------------------------------------
   
  const [Resultdata, setResultdata] = useState('');
  // const [Resultdata, setResultdata] = useState('');
  const ResultdataHandler = data => {
    setResultdata(()=> data);
  };


  // -----------------------------------------------------------R E S U L T I M A G E------------------------------------------------------------------
  const [ResultImagedata, setResultImagedata] = useState('');
  const ResultImagedataHandler = data => {
    setResultImagedata(()=>data);
  };

  const value = {
    singleOrGroup: singleOrGroupdata,
    singleOrGroupHandler: singleOrGroupHandler,

    meal: mealdata,
    mealHandler: mealdataHandler,

    handsOrCut: handsOrCutdata,
    handsOrCutHandler: handsOrCutdataHandler,

    sweetOrSav: sweetOrSavdata,
    sweetOrSavHandler: sweetOrSavdataHandler,

    spicyOrMild: spicyOrMilddata,
    spicyOrMildHandler: spicyOrMilddataHandler,

    adventurousOrRel: adventurousOrReldata,
    adventurousOrRelHandler: adventurousOrRelHandler,

    bigMealOrLight: bigMealOrLightdata,
    bigMealOrLightHandler: bigMealOrLightdataHandler,

    sdnb: SDNBdata,
    sdnbHandler: SDNBdataHandler,

    Result: Resultdata,
    ResultHandler: ResultdataHandler,

    ResultImage: ResultImagedata,
    ResultImageHandler: ResultImagedataHandler,
  };

  return <Data.Provider value={value}>{children}</Data.Provider>;
};
export default Storage;
export {Data};