

export const Images ={
    Indian : require('./Assets/Images/PNG/indianfood.png'),
    Burger : require("./Assets/Images/Results_Emoji/Burger.png"),
    Chicken : require("./Assets/Images/Results_Emoji/Chicken.png"),
    BBQ : require("./Assets/Images/Results_Emoji/BarBQ.png"),
    Kebab : require("./Assets/Images/Results_Emoji/Kebab.png"),
    "Fish and Chips" : require("./Assets/Images/Results_Emoji/FishnChips.png"),
    Caribbean : require("./Assets/Images/Results_Emoji/Caribbean.png"),
    Chinese : require("./Assets/Images/Results_Emoji/Chinese.png"),
    Deserts : require("./Assets/Images/Results_Emoji/Deserts.png"),
    "Coffee Shop" : require('./Assets/Images/Results_Emoji/Coffee.png'),
    Sandwich : require("./Assets/Images/Results_Emoji/Sandwich.png"),
    Japanese : require("./Assets/Images/Results_Emoji/Japanese.png"),
    Cafe :  require('./Assets/Images/Results_Emoji/Cafe.png'),
    Pizza : require('./Assets/Images/Results_Emoji/Pizza.png')
}